import {LIST_BILLING_PLANS_FAILURE, LIST_BILLING_PLANS_REQUEST, LIST_BILLING_PLANS_SUCCESS} from '../actions/billingActions'

export function billingPlansReducer(state = [], action) {
    switch (action.type) {
        case LIST_BILLING_PLANS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case LIST_BILLING_PLANS_FAILURE:
            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case LIST_BILLING_PLANS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.response
            });
        default:
            return state
    }
}
