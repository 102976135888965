import {CALL_API} from '../middleware/api'

export const INPUT_LIST_REQUEST = 'INPUT_LIST_REQUEST';
export const INPUT_LIST_SUCCESS = 'INPUT_LIST_SUCCESS';
export const INPUT_LIST_FAILURE = 'INPUT_LIST_FAILURE';


export function fetchInputList() {

    return {
        [CALL_API]: {
            endpoint: 'inputs',
            method: 'GET',
            types: [INPUT_LIST_SUCCESS, INPUT_LIST_FAILURE, INPUT_LIST_REQUEST]
        }
    }
}

