import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import {bindActionCreators, compose} from "redux";
import {Grid, Toolbar, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import LoadingButton from "../../Utilities/LoadingButton";
import SaveIcon from '@material-ui/icons/Save'
import Rest from "../../../core/Rest";
import {fetchUser} from "../../../actions/userActions";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(2),
    }
});

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isFetching: false,
            isSaving: false
        }
    }

    componentDidMount() {
        this.fetchUser();
    }

    onModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const state = this.state;
        state[name] = value;

        this.setState(state);
    };

    fetchUser = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'user',
        }).then(
            response => {
                this.setState({
                    email: response.response.email
                });
                this.props.actions.fetchUser();
            },
            error => {
            }
        ).then(() => {
            this.setState({isFetching: false});
        });
    };

    saveUser = (e) => {
        e.preventDefault();

        this.setState({isSaving: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'user',
            body: {
                email: this.state.email
            }
        }).then(
            response => {
                this.setState({
                    email: response.response.email
                })
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    render() {
        const classes = this.props.classes;

        return <div className={classes.root}>
            <form noValidate>
                <Toolbar disableGutters={true}>
                    <LoadingButton variant='contained' color='primary'
                                   type='submit' loading={this.state.isSaving}
                                   onClick={this.saveUser}
                                   disabled={this.state.isFetching}>
                        <SaveIcon/> {this.props.t('save')}
                    </LoadingButton>
                </Toolbar>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <TextField
                            required={true}
                            key='email'
                            fullWidth
                            variant="outlined"
                            label={this.props.t('profile.email')}
                            value={this.state.email}
                            name='email'
                            onChange={this.onModelChange}
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUser}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Profile);