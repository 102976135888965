import {CALL_API} from '../middleware/api'

export const NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_LIST_REQUEST';
export const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS';
export const NOTIFICATIONS_LIST_FAILURE = 'NOTIFICATIONS_LIST_FAILURE';


export function fetchNotificationsList() {

    return {
        [CALL_API]: {
            endpoint: 'notifications',
            method: 'GET',
            types: [NOTIFICATIONS_LIST_SUCCESS, NOTIFICATIONS_LIST_FAILURE, NOTIFICATIONS_LIST_REQUEST]
        }
    }
}

