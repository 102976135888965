import React from 'react';
import logo from '../../img/LogoWhite.png';
import {Grid, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = theme => ({
    root: {
        height: '100vh',
        backgroundColor: theme.palette.primary.light
    },
    image: {
        marginBottom: theme.spacing(2)
    }
});

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
    }
})(LinearProgress);

const LoadingScreen = (props) => {
    const classes = props.classes;

    const spinner = props.isFetching ? <Typography align={"center"} component='div'>
        <BorderLinearProgress />
    </Typography> : '';

    return (
        <Grid container justify="center" alignItems="center" component="main" className={classes.root}>
            <Grid item xs={11} sm={5} md={6} >
                <img src={logo} width="100%" alt={"logo"} className={classes.image} />
                {spinner}
            </Grid>
        </Grid>
    );
};

export default withStyles(useStyles)(LoadingScreen);
