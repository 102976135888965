export function requestCountReducer(state = {
    count: 0,
}, action) {

    switch (action.type) {
        case 'ADD_REQUEST':
            return {
                count: state.count + 1
            };
        case 'REMOVE_REQUEST':

            let newCount = state.count - 1;

            return {
                count: newCount <= 0 ? 0 : newCount
            };
        default:
            return state
    }
}
