import React, {Component} from 'react';
import {Route, Switch} from "react-router"
import Payment from "../Payment/Payment";
import Success from "./Success";


class Router extends Component {

    render() {

        const match = this.props.match;


        return <Switch>
            <Route key={1} exact path={match.url} component={Payment}/>
            <Route key={1} exact path={match.url + '/success'} component={Success}/>
        </Switch>

    }
}

export default Router