import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save'
import {Box, MenuItem, Paper, TextField, Toolbar} from '@material-ui/core'
import SimpleSelect from "../Utilities/SimpleSelect";
import LoadingButton from "../Utilities/LoadingButton";
import {connect} from "react-redux";
import Rest from "../../core/Rest";
import {ButtonLink} from "../Utilities/ButtonLink";
import CloseIcon from "@material-ui/icons/Close";
import {fetchNotificationsList} from "../../actions/notificationActions";
import Divider from "@material-ui/core/Divider";
import GenericDataFields from "../DataFields/GenericDataFields";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginBottom: theme.spacing(1)
    },
    grow: {
        flexGrow: 1,
    },
    saveButton: {
        marginRight: theme.spacing(1)
    },
});

class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            isSaving: false,
            outputType: '',
            name: '',
            waitTime: 0,
            data: {}
        }
    }

    componentDidMount() {
        this.fetchNotification();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchNotification();
        }
    }

    onModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        this.setState({...this.state, [name]: value});
    };

    onDataChange = (name, value) => {
        const data = this.state.data;
        data[name] = value;
        this.setState({data: data});
    };

    saveNotification = (e) => {
        e.preventDefault();

        this.setState({isSaving: true});

        const data = Object.keys(this.state.data).map((identifier) => {
            return {
                identifier: identifier,
                value: this.state.data[identifier]
            }
        });

        Rest.fetch({
            method: 'PUT',
            endpoint: 'notifications/' + this.props.match.params.id,
            body: {
                name: this.state.name,
                waitSeconds: this.state.waitTime,
                data: data,
            }
        }).then(
            response => {
                this.props.actions.fetchNotificationsList();
            },
            e => null
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    fetchNotification = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'notifications/' + this.props.match.params.id,
        }).then(
            response => {
                const data = {};
                response.response.data.forEach((entry) => {
                    data[entry.identifier] = entry.value
                });

                this.setState({
                    name: response.response.name,
                    outputType: response.response.output,
                    waitTime: response.response.waitSeconds,
                    data: data,
                })
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    };


    testNotification = (e) => {
        e.preventDefault();

        this.setState({isTesting: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'notifications/' + this.props.match.params.id + '/test'
        }).then(
            response => {
                this.props.sendNotification('Test done.', 'success')
            },
            e => null
        ).then(() => {
            this.setState({isTesting: false});
        });
    };

    render() {
        const classes = this.props.classes;

        const menuOutputs = this.props.outputs.map((output) => {
            return <MenuItem key={output.identifier} value={output.identifier}>{output.name}</MenuItem>;
        });

        return <div>
            <Toolbar disableGutters={true}>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <ButtonLink variant="contained" color="primary" to={'/notifications'}>
                        <CloseIcon/> {this.props.t('close')}
                    </ButtonLink>
                </Box>
            </Toolbar>
            <Paper className={classes.paper}>
                <form noValidate>
                    <Toolbar disableGutters={true}>
                        <LoadingButton className={classes.saveButton} variant='contained' color='primary'
                                       type='submit' loading={this.state.isSaving}
                                       onClick={this.saveNotification}
                                       disabled={this.state.isFetching}>
                            <SaveIcon/> {this.props.t('save')}
                        </LoadingButton>
                        <LoadingButton variant='outlined' loading={this.state.isTesting} onClick={this.testNotification}
                                       disabled={this.state.isFetching || this.state.isSaving}>
                            <DoneIcon/> {this.props.t('test')}
                        </LoadingButton>
                    </Toolbar>
                    <TextField label={this.props.t('name')}
                               className={classes.formControl}
                               variant="outlined"
                               margin="normal"
                               name='name'
                               value={this.state.name}
                               onChange={this.onModelChange}
                               required
                               fullWidth
                    />
                    <TextField label={this.props.t('notifications.waitTime')}
                               className={classes.formControl}
                               variant="outlined"
                               margin="normal"
                               type="number"
                               name='waitTime'
                               value={this.state.waitTime}
                               onChange={this.onModelChange}
                               required
                               fullWidth
                    />
                    <SimpleSelect value={this.state.outputType} onChange={this.onModelChange} name='outputType'
                                  label={this.props.t('notifications.outputType')} className={classes.formControl} disabled fullWidth
                                  required>
                        {menuOutputs}
                    </SimpleSelect>
                    {this.state.outputType && <Fragment>
                        <Divider className={classes.divider}/>
                        <GenericDataFields type='outputs' identifier={this.state.outputType} onChange={this.onDataChange}
                                           data={this.state.data}/>
                    </Fragment>}
                </form>
            </Paper>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchNotificationsList}, dispatch),
        sendNotification: (message, style) => {
            dispatch({
                type: 'ADD_ALERT',
                message: message,
                style: style
            });
        }
    }
};

function mapStateToProps(state) {
    const outputs = state.outputs.list || [];
    return {outputs}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Edit);