import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';

i18n
    .use(XHR)
    .init({
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false,
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    });

export default i18n;