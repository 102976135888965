import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Paper} from "@material-ui/core";
import {Route, Switch} from "react-router";
import Information from "./Information/Information";
import {push} from "connected-react-router";
import Subscription from "./Information/Subscription";

const useStyles = theme => ({});

class Profile extends Component {

    render() {
        const match = this.props.match;

        return <Paper>
            <Tabs
                value={this.props.location.pathname}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e,v) => this.props.tabClicked(v)}
                aria-label="disabled tabs example"
            >
                <Tab label={this.props.t('navigation.information')} value='/profile' />
                <Tab label={this.props.t('navigation.subscription')} value='/profile/subscription'/>
            </Tabs>
            <Switch>
                <Route exact path={match.url} component={Information}/>
                <Route path={match.url + '/subscription'} component={Subscription}/>
            </Switch>
        </Paper>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tabClicked: (tab) => dispatch(push(tab)),
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Profile);