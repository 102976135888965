import React, {Component} from 'react';
import {bindActionCreators, compose} from "redux";
import {Grid, MenuItem, Typography, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Rest from "../../../core/Rest";
import SimpleSelect from "../../Utilities/SimpleSelect";
import {fetchUser} from "../../../actions/userActions";

const useStyles = theme => ({
    root: {},
});


class Address extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countryIsFetching: false,
            addressIsFetching: false,
            countries: [],
            address: {
                country: '',
                firstName: '',
                lastName: '',
                company: '',
                email: '',
                streetAddress: '',
                extendedAddress: '',
                region: '',
                city: '',
                postalCode: ''
            }

        }
    }

    componentDidMount() {
        this.props.setIsFetching(false);
        this.fetchCountries();
        this.fetchAddress();
        this.props.registerNextCallback(() => {
            return this.saveAddress()
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (
            prevState.countryIsFetching !== this.state.countryIsFetching ||
            prevState.addressIsFetching !== this.state.addressIsFetching
        ) {
            if (this.state.countryIsFetching || this.state.addressIsFetching) {
                this.props.setIsFetching(true);
            } else {
                this.props.setIsFetching(false);
            }
        }
    }

    saveAddress = () => {
        return new Promise((resolve, reject) => {

            Rest.fetch({
                method:  'POST',
                endpoint: 'billing/address',
                body: {
                    country: this.state.address.country,
                    firstName: this.state.address.firstName,
                    lastName: this.state.address.lastName,
                    company: this.state.address.company.length ? this.state.address.company : undefined,
                    email: this.state.address.email,
                    streetAddress: this.state.address.streetAddress,
                    extendedAddress: this.state.address.extendedAddress.length ? this.state.address.extendedAddress : undefined,
                    region: this.state.address.region.length ? this.state.address.region : undefined,
                    city: this.state.address.city,
                    postalCode: this.state.address.postalCode
                }
            }).then(
                response => {
                    this.props.actions.fetchUser();
                    resolve()
                },
                error => {
                    reject()
                }
            )
        });
    };

    fetchCountries = () => {
        this.setState({
            countryIsFetching: true
        });

        Rest.fetch({
            method: 'GET',
            endpoint: 'billing/countries',
        }).then(
            response => {
                this.setState({
                    countries: response.response,
                })
            },
            error => {
            }
        ).then(() => {
            this.setState({
                countryIsFetching: false
            });
        });
    };

    fetchAddress = () => {
        this.setState({
            addressIsFetching: true
        });

        Rest.fetch({
            method: 'GET',
            endpoint: 'billing/address',
            ignoreErrorCodes: [404]
        }).then(
            response => {
                this.setState({
                    address:  Object.assign({}, this.state.address, response.response)
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({
                addressIsFetching: false
            });
        });
    };

    onModelChange = (e) => {
        const address = this.state.address;
        address[e.target.name] = e.target.value;

        this.setState({address});
    };

    render() {

        const classes = this.props.classes;

        const countryInputs = this.state.countries.map((country) => {
            return <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>;
        });

        return <div className={classes.root}>
            <Typography variant='h4'>Address</Typography>
            <form autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <TextField label={this.props.t('address.firstName')} onChange={this.onModelChange}
                                   value={this.state.address.firstName} name='firstName' variant="outlined" margin='normal' fullWidth
                                   required/>
                        <TextField label={this.props.t('address.lastName')} onChange={this.onModelChange}
                                   value={this.state.address.lastName} name='lastName' variant="outlined" margin='normal' fullWidth
                                   required/>
                        <TextField label={this.props.t('address.company')} onChange={this.onModelChange} value={this.state.address.company}
                                   name='company' variant="outlined" margin='normal' fullWidth/>
                        <TextField label={this.props.t('address.email')} onChange={this.onModelChange} value={this.state.address.email}
                                   name='email' variant="outlined" margin='normal' fullWidth required/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField label={this.props.t('address.streetAddress')} onChange={this.onModelChange}
                                   value={this.state.address.streetAddress} name='streetAddress' variant="outlined" margin='normal'
                                   fullWidth required/>
                        <TextField label={this.props.t('address.extendedAddress')} onChange={this.onModelChange}
                                   value={this.state.address.extendedAddress} name='extendedAddress' variant="outlined" margin='normal'
                                   fullWidth/>
                        <TextField label={this.props.t('address.region')} onChange={this.onModelChange} value={this.state.address.region}
                                   name='region' variant="outlined" margin='normal' fullWidth/>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <TextField label={this.props.t('address.city')} onChange={this.onModelChange}
                                           value={this.state.address.city} name='city' variant="outlined" margin='normal' fullWidth
                                           required/>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField label={this.props.t('address.postalCode')} onChange={this.onModelChange}
                                           value={this.state.address.postalCode} name='postalCode' variant="outlined" margin='normal'
                                           fullWidth required/>
                            </Grid>
                        </Grid>
                        <SimpleSelect margin='normal' value={this.state.address.country} onChange={this.onModelChange} name='country'
                                      label={this.props.t('address.country')} fullWidth required>
                            {countryInputs}
                        </SimpleSelect>
                    </Grid>
                </Grid>
            </form>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUser}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Address);