import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const ValidationMessages = (props) => {

    const {t} = useTranslation('validation');
    const validationErrors = props.validationErrors;
    return Object.keys(validationErrors).map((key) => {

        const list = validationErrors[key].join(', ');

        return <Fragment>
            <li>{t('keys.' + key)}: {list}</li>
        </Fragment>;
    });
};

export default ValidationMessages