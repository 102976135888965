import {CALL_API} from '../middleware/api'

export const OUTPUT_LIST_REQUEST = 'OUTPUT_LIST_REQUEST';
export const OUTPUT_LIST_SUCCESS = 'OUTPUT_LIST_SUCCESS';
export const OUTPUT_LIST_FAILURE = 'OUTPUT_LIST_FAILURE';


export function fetchOutputList() {

    return {
        [CALL_API]: {
            endpoint: 'outputs',
            method: 'GET',
            types: [OUTPUT_LIST_SUCCESS, OUTPUT_LIST_FAILURE, OUTPUT_LIST_REQUEST]
        }
    }
}

