import React, {Component} from 'react';
import {compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import AddIcon from '@material-ui/icons/Add'
import {Box, MenuItem, Paper, Toolbar} from '@material-ui/core'
import LoadingButton from "../../Utilities/LoadingButton";
import Rest from "../../../core/Rest";
import {ButtonLink} from "../../Utilities/ButtonLink";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "./Notification";
import SimpleSelect from "../../Utilities/SimpleSelect";

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    formControl: {
        paddingBottom: theme.spacing(1)
    },
});

class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            isSaving: false,
            notificationOpen: null,
            watchNotifications: [],
            notifications: [],
            selected: ''
        }
    }

    componentDidMount() {
        this.fetchWatchNotifications();
        this.fetchNotifications();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchWatchNotifications();
        }
    }


    fetchWatchNotifications = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'watches/' + this.props.match.params.id + '/notifications',
        }).then(
            response => {
                this.setState({
                    watchNotifications: response.response
                })
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    };

    fetchNotifications = () => {
        Rest.fetch({
            method: 'GET',
            endpoint: 'notifications',
        }).then(
            response => {
                this.setState({
                    notifications: response.response
                })
            }
        );
    };

    saveWatchNotification = (e) => {
        e.preventDefault();

        this.setState({isSaving: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'watch_notifications',
            body: {
                watchId: this.props.match.params.id,
                notificationId: this.state.selected
            }
        }).then(
            response => {
                this.setState({
                    selected: ''
                });
                this.fetchWatchNotifications();
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    onModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        this.setState({...this.state, [name]: value});
    };

    render() {
        const classes = this.props.classes;

        const menuOutputs = this.state.notifications.filter((notification) => {

            const mapped = this.state.watchNotifications.map((watchNotifications) => {
                return watchNotifications.notification.id;
            });

            return mapped.indexOf(notification.id) === -1

        }).map((notification) => {
            return <MenuItem key={notification.id} value={notification.id}>{notification.name}</MenuItem>;
        });

        const watchNotifications = this.state.watchNotifications.map((watchNotifications) => {
            return <Notification
                notification={watchNotifications}
                setOpen={(open) => {
                    this.setState({
                        notificationOpen: open ? watchNotifications.id : null
                    })
                }}
                onDelete={() => {
                    this.fetchWatchNotifications();
                }}
                onSave={() => {
                    this.fetchWatchNotifications();
                }}
                open={this.state.notificationOpen === watchNotifications.id}/>
        });

        return <div>
            <Toolbar disableGutters={true}>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <ButtonLink variant="contained" color="primary" to={'/'}>
                        <CloseIcon/> {this.props.t('close')}
                    </ButtonLink>
                </Box>
            </Toolbar>
            <Paper className={classes.paper}>
                <form noValidate>
                    <SimpleSelect value={this.state.selected} name='selected' onChange={this.onModelChange}
                                  label={this.props.t('notifications.title')} className={classes.formControl} fullWidth required>
                        {menuOutputs}
                    </SimpleSelect>
                    <LoadingButton variant='contained' color='primary' type='submit' loading={this.state.isSaving}
                                   onClick={this.saveWatchNotification}
                                   disabled={this.state.isFetching}>
                        <AddIcon/> {this.props.t('add')}
                    </LoadingButton>
                </form>
            </Paper>
            <div>
                {watchNotifications}
            </div>
        </div>
    }
}

export default compose(
    withStyles(useStyles),
    withTranslation())(Edit);