import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";
import {history, store} from '../../core';
import LoggedIn from "../LoggedIn/LoggedIn";
import {Route, Switch} from "react-router";
import {useTranslation} from "react-i18next";
import {SnackbarProvider} from "notistack";
import Snackbars from "../Snackbar/Snackbars";
import Button from "@material-ui/core/Button";
import CognitoLogin from "../Login/CognitoLogin";

const theme = createMuiTheme({
    palette: {
        common: {
            black: "#000000",
            white: "#FFFFFF"
        },
        background: {
            paper: "#FFFFFF",
            default: "#ECECEC"
        },
        primary: {
            light: "#428E92",
            main: "#006064",
            dark: "#00363A",
            contrastText: "#FFFFFF"
        },
        secondary: {
            light: "#F9683A",
            main: "#BF360C",
            dark: "#870000",
            contrastText: "#FFFFFF"
        },
        error: {
            light: "rgba(245, 166, 35, 1)",
            main: "rgba(208, 2, 27, 1)",
            dark: "rgba(139, 87, 42, 1)",
            contrastText: "#FFFFFF"
        },
        success: {
            light: "#6FBF73",
            main: "#4CAF50",
            dark: "#357A38",
            contrastText: "#FFFFFF"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    }
});

const App = () => {

    // add action to all snackbars
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    };

    const {t} = useTranslation();

    // customized
    const action = key => (<Button onClick={onClickDismiss(key)}>
        {t('dismiss')}
    </Button>);

    return <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <SnackbarProvider ref={notistackRef} maxSnack={3} action={action} autoHideDuration={6000} preventDuplicate={true}>
                <Snackbars/>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path="/login" component={CognitoLogin}/>
                        <Route component={LoggedIn}/>
                    </Switch>
                </ConnectedRouter>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>
};

export default App;