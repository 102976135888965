import React, {Component} from 'react';
import {bindActionCreators, compose} from "redux";
import {Box, Paper, Toolbar, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Address from "./Steps/Address";
import LoadingButton from "../Utilities/LoadingButton";
import Order from "./Steps/Order";
import Plan from "./Steps/Plan";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(2),
    },
    body: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    dropInContainer: {
        marginBottom: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
});


class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: 1,
            isFetching: false,
            isLoading: false,
            executionPackages: 0,
            selectedPlan: ''
        }
    }

    nextStep = () => {
        this.setState({
            isLoading: true
        });


        this.state.nextCallback().then(() => {
            if (this.state.current >= 3) {
                return;
            }

            this.setState({
                current: this.state.current + 1
            });
        }, () => {
        }).then(() => {
            this.setState({
                isLoading: false
            });
        });

    };

    backStep = () => {
        if (this.state.current <= 1) {
            return;
        }

        this.setState({
            current: this.state.current - 1
        });
    };

    getCurrentBody = () => {
        switch (this.state.current) {
            case 1:
                return <Address registerNextCallback={(callback) => {
                    this.setState({
                        nextCallback: callback
                    });
                }} setIsFetching={(status) => {
                    this.setState({isFetching: status})
                }
                }/>;
            case 2:
                return <Plan
                    setInfos={(plan, executionPackages) => {
                        this.setState({selectedPlan: plan, executionPackages: executionPackages})
                    }}
                    registerNextCallback={(callback) => {
                        this.setState({
                            nextCallback: callback
                        });
                    }}
                    setIsFetching={(status) => {
                        this.setState({isFetching: status})
                    }}
                />;
            case 3:
                return <Order executionPackages={this.state.executionPackages} selectedPlan={this.state.selectedPlan} registerNextCallback={(callback) => {
                    this.setState({
                        nextCallback: callback
                    });
                }} setIsFetching={(status) => {
                    this.setState({isFetching: status})
                }
                }/>;
            default:
                return '';
        }
    };

    render() {

        const classes = this.props.classes;

        const steps = {1: 'Address', 2: 'Select your plan', 3: 'Order subscription'};

        return <Paper className={classes.root}>
            <Stepper activeStep={this.state.current - 1}>
                {Object.keys(steps).map((index) => {
                    const label = steps[index];

                    return (
                        <Step key={index} completed={index < this.state.current}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div className={classes.body}>
                {this.getCurrentBody()}
            </div>
            <Toolbar>
                <Box justifyContent="flex-end">
                    {this.state.current > 1 && <Button onClick={this.backStep}>Previous</Button>}
                </Box>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    {this.state.current <= 2 &&
                    <LoadingButton onClick={this.nextStep} variant='contained' disabled={this.state.isFetching}
                                   loading={this.state.isLoading}
                                   color='primary'>Next</LoadingButton>}
                    {this.state.current === 3 &&
                    <LoadingButton onClick={this.nextStep} variant='contained' disabled={this.state.isFetching}
                                   loading={this.state.isLoading}
                                   color='primary'>{this.props.t('billing.buy')}</LoadingButton>}
                </Box>
            </Toolbar>
        </Paper>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    return {}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Payment);