import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from 'redux';
import {history} from '../../core';
import logo from '../../img/LogoHorizontalWhite.png';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WatchIcon from '@material-ui/icons/Watch';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    withStyles
} from "@material-ui/core";
import clsx from "clsx";
import {Link as RouterLink} from "react-router-dom";
import {withTranslation} from "react-i18next";

const drawerWidth = 240;

const BorderLinearProgress = withStyles({
    root: {
        height: 2,
    }
})(LinearProgress);

const useStyles = theme => ({
    logo: {
        maxHeight: '32px'
    },
    list: {
        marginTop: theme.spacing(2)
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    grow: {
        flexGrow: 1,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
});

const ListItemLink = (props) => {
    const {icon, primary, to, external, exact} = props;
    const pathname = history.location.pathname;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => {

                if (external) {
                    // eslint-disable-next-line
                    return <a href={to} target='_blank' ref={ref} rel="noopener noreferrer"/>
                }

                return <RouterLink to={to} {...itemProps} innerRef={ref}/>
            }),
        [to, external],
    );

    let selected = false;
    if (exact && to === pathname) {
        selected = true;
    } else if (!exact && pathname.indexOf(to) === 0) {
        selected = true;
    }

    return (
        <ListItem button component={renderLink} selected={selected} key={to}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary}/>
        </ListItem>
    );
};

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            drawerOpen: false
        };
    }

    handleDrawerOpen = () => {
        this.setState({drawerOpen: true});
    };

    handleDrawerClose = () => {
        this.setState({drawerOpen: false});
    };

    render() {
        const classes = this.props.classes;
        const open = this.state.drawerOpen;

        return <Fragment>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <img src={logo} alt="logo" className={classes.logo}/>
                    <div className={classes.grow}/>
                </Toolbar>
                {Boolean(this.props.requestCount.count) && <BorderLinearProgress color="secondary"/>}
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={this.handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List className={classes.list}>
                    <ListItemLink primary={this.props.t('navigation.watches')} icon={<WatchIcon/>} to={'/watches'} key={1}/>
                    <ListItemLink primary={this.props.t('navigation.notification')} icon={<NotificationsIcon/>} to={'/notifications'}
                                  key={2}/>
                    <ListItemLink primary={this.props.t('navigation.profile')} icon={<PersonIcon/>} to={'/profile'}
                                  key={3}/>
                    <ListItemLink primary={this.props.t('navigation.logout')} icon={<ExitToAppIcon/>} to={'/login'}
                                  key={4}/>
                </List>
            </Drawer>
        </Fragment>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {

    const requestCount = state.requestCount;

    return {requestCount}
}

export default compose(
    withStyles(useStyles),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps))(Navigation);
