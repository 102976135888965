import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save'
import {Dialog, DialogActions,List, ListItem, DialogTitle, Box, Button, Divider, ListItemText, MenuItem, Paper, TextField, Toolbar} from '@material-ui/core'
import SimpleSelect from "../Utilities/SimpleSelect";
import LoadingButton from "../Utilities/LoadingButton";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import Rest from "../../core/Rest";
import {ButtonLink} from "../Utilities/ButtonLink";
import CloseIcon from "@material-ui/icons/Close";
import {fetchNotificationsList} from "../../actions/notificationActions";
import HelpIcon from '@material-ui/icons/Help';

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formControl: {
        paddingBottom: theme.spacing(1)
    },
    grow: {
        flexGrow: 1,
    },
    moreInfoButton: {
        color: theme.palette.text.secondary
    }
});

class New extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            outputType: '',
            name: '',
            infoDialogOpen: false
        }
    }


    openInfoDialog = () => {
        this.setState({
            infoDialogOpen: true
        })
    };

    closeInfoDialog = () => {
        this.setState({
            infoDialogOpen: false
        })
    };

    onModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const state = this.state;
        state[name] = value;

        this.setState(state);
    };

    saveNotification = (e) => {
        e.preventDefault();

        this.setState({isSaving: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'notifications',
            body: {
                name: this.state.name,
                output: this.state.outputType
            }
        }).then(
            response => {
                this.props.actions.fetchNotificationsList();
                this.props.openNotification(response.response.id);
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    render() {
        const classes = this.props.classes;

        const menutOutputs = this.props.outputs.map((output) => {
            return <MenuItem key={output.identifier} value={output.identifier}>{output.name}</MenuItem>;
        });


        const outputTypeDescriptions = this.props.outputs.map((output, i) => {
            return <Fragment key={output.identifier}><ListItem key={output.identifier}>
                <ListItemText primary={output.name} secondary={<div dangerouslySetInnerHTML={{__html: output.description}}/>}/>
            </ListItem>
                {i + 1 < this.props.outputs.length && <Divider variant="middle"/>}
            </Fragment>;
        });

        return <div>
            <Toolbar disableGutters={true}>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <ButtonLink variant="contained" color="primary" to={'/notifications'}>
                        <CloseIcon/> {this.props.t('close')}
                    </ButtonLink>
                </Box>
            </Toolbar>
            <Paper className={classes.paper}>
                <form noValidate>
                    <LoadingButton variant='contained' color='primary' type='submit' loading={this.state.isSaving}
                                   onClick={this.saveNotification}>
                        <SaveIcon/> {this.props.t('save')}
                    </LoadingButton>
                    <TextField label={this.props.t('name')}
                               className={classes.formControl}
                               variant="outlined"
                               margin="normal"
                               name='name'
                               value={this.state.name}
                               onChange={this.onModelChange}
                               required
                               fullWidth
                    />
                    <SimpleSelect value={this.state.outputType} onChange={this.onModelChange} name='outputType'
                                  label={this.props.t('notifications.outputType')} className={classes.formControl} fullWidth required>
                        {menutOutputs}
                    </SimpleSelect>
                    <Button
                        className={classes.moreInfoButton}
                        size="small"
                        onClick={this.openInfoDialog}
                        startIcon={<HelpIcon/>}
                    >
                        {this.props.t('notifications.moreOutputTypeInfo')}
                    </Button>
                </form>
            </Paper>
            <Dialog onClose={this.closeInfoDialog} open={this.state.infoDialogOpen}>
                <DialogTitle>{this.props.t('notifications.moreOutputTypeInfo')}</DialogTitle>
                <List>
                    {outputTypeDescriptions}
                </List>
                <DialogActions>
                    <Button onClick={this.closeInfoDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchNotificationsList}, dispatch),
        openNotification: (id) => dispatch(push('/notifications/' + id + '/edit'))
    }
};

function mapStateToProps(state) {
    const outputs = state.outputs.list || [];
    return {outputs}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(New);