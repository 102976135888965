import React, {Component} from 'react';
import {getPlanDetails, PLANS} from "../../../service/BillingPlans";
import Grid from "@material-ui/core/Grid";
import StarIcon from '@material-ui/icons/Star'
import Card from "@material-ui/core/Card";
import {withTranslation} from "react-i18next";
import {CardHeader, withStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";

const useStyles = theme => ({
    root: {},
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    cardSelected: {
        border: '2px solid',
        borderColor: theme.palette.secondary.main
    },
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
});


class Plan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            additionalExecutions: 0,
            selectedPlan: ''
        }
    }

    componentDidMount() {
        this.props.setIsFetching(true);
        this.props.registerNextCallback(() => {
            return new Promise((resolve, reject) => {
                resolve();
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.selectedPlan !== this.state.selectedPlan ||
            prevState.additionalExecutions !== this.state.additionalExecutions
        ) {
            this.props.setInfos(this.state.selectedPlan, Math.ceil(this.state.additionalExecutions / 10000));
        }

    }

    selectPlan = (plan) => {
        this.setState({selectedPlan: plan});
        this.props.setIsFetching(false);
    };

    sliderChange = (e, newValue) => {
        const value = Math.ceil(newValue / 10000) * 10000;
        this.setState({additionalExecutions: value})
    };

    inputChange = (e) => {
        const value = Math.ceil(e.target.value / 10000) * 10000;
        this.setState({additionalExecutions: value})
    };

    render() {

        const classes = this.props.classes;

        const plans = Object.keys(PLANS).map((planKey) => {
            const plan = getPlanDetails(planKey);

            let button = <Button fullWidth variant={planKey === 'extended' ? 'contained' : 'outlined'} color="primary"
                                 onClick={() => this.selectPlan(planKey)}>
                Select Plan
            </Button>;
            if (this.state.selectedPlan === planKey) {
                button = <Button fullWidth variant='contained' color='secondary'>
                    Selected Plan
                </Button>
            }

            return <Grid item key={planKey} xs={12} sm={4} md={3}>
                <Card className={this.state.selectedPlan === planKey ? classes.cardSelected : ''}>
                    <CardHeader
                        title={plan.label}
                        titleTypographyProps={{align: 'center'}}
                        subheaderTypographyProps={{align: 'center'}}
                        action={planKey === 'extended' ? <StarIcon/> : null}
                    />
                    <CardContent className={classes.features}>
                        <div className={classes.cardPricing}>
                            <Typography component="h2" variant="h3" color="textPrimary">
                                {plan.price}
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                                €/mo
                            </Typography>
                        </div>
                        <ul>
                            <Typography component="li" variant="subtitle1" align="center" key={1}>
                                100 Watches
                            </Typography>
                            <Typography component="li" variant="subtitle1" align="center" key={2}>
                                100 Notifications
                            </Typography>
                            <Typography component="li" variant="subtitle1" align="center" key={3}>
                                {plan.executions} Executions inclusive
                            </Typography>
                            <Typography component="li" variant="subtitle1" align="center" key={4}>
                                Minimum interval {plan.minimumInterval} minutes
                            </Typography>
                            {plan.hasMetrics && <Typography component="li" variant="subtitle1" align="center" key={5}>
                                Monitoring Metrics
                            </Typography>}
                            {!this.props.user.userObject.hadTrial && <Typography component="li" variant="subtitle1" align="center" key={6}>
                                <b>14 Days trial period</b>
                            </Typography>}
                        </ul>
                    </CardContent>
                    <CardActions>
                        {button}
                    </CardActions>
                </Card>
            </Grid>
        });

        return <div className={classes.root}>
            <Grid container spacing={5} justify="center" alignItems='center'>
                {plans}
            </Grid>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    const {user} = state;
    return {user}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Plan);