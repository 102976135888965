import {USER_SUCCESS, USER_REQUEST, USER_FAILURE} from "../actions/userActions";

export function userReducer(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
        case USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case USER_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case USER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                userObject: action.response
            });
        default:
            return state
    }
}

