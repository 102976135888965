import React, {Component, Fragment} from "react";
import {compose} from "redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    withStyles
} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Rest from "../../core/Rest";
import TextField from "@material-ui/core/TextField";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import SimpleSelect from "../Utilities/SimpleSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import HelpIcon from '@material-ui/icons/Help';
import Chip from "@material-ui/core/Chip";

const useStyles = theme => ({
    formControl: {
        paddingBottom: theme.spacing(1)
    },
    jsonLabel: {
        paddingBottom: theme.spacing(0.5)
    },
    moreInfoButton: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1)
    }
});

class GenericDataFields extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataFields: [],
            infoDialogOpen: false
        }
    }

    openInfoDialog = () => {
        this.setState({
            infoDialogOpen: true
        })
    };

    closeInfoDialog = () => {
        this.setState({
            infoDialogOpen: false
        })
    };

    componentDidMount() {
        this.loadDataFields();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.type !== prevProps.type ||
            this.props.identifier !== prevProps.identifier
        ) {
            this.loadDataFields();
        }
    }

    loadDataFields = () => {
        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: this.props.type + '/' + this.props.identifier + '/fields',
        }).then(
            response => {
                this.setState({
                    dataFields: response.response
                })
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    };

    onDataModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        this.props.onChange(name, value)
    };

    onDataModelCheckboxChange = (e) => {
        const value = e.target.checked;
        const name = e.target.name;

        this.props.onChange(name, value)
    };

    onJsonChange = name => (value) => {
        if (!value.error) {
            this.props.onChange(name, value.json)
        }
    };

    getDataField = (dataField) => {

        const classes = this.props.classes;

        if (dataField.type === 'json') {
            let placeholder = {};
            try {
                placeholder = JSON.parse(this.props.data[dataField.identifier] ?? '{}');
            } catch (e) {
            }

            return <div className={classes.formControl}>
                <InputLabel required={dataField.required} className={classes.jsonLabel}>{dataField.label}</InputLabel>
                <JSONInput
                    onChange={this.onJsonChange(dataField.identifier)}
                    placeholder={placeholder}
                    width='100%'
                    style={{
                        contentBox: {
                            color: '#252525' // <-- the color that you want content to be prior render
                        },
                        outerBox: {
                            borderRadius: '4px',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            borderStyle: 'solid',
                            borderWidth: '1px'
                        }
                    }}
                    colors={
                        {
                            default: '#252525',
                            background: '#FFFFFF',
                            background_warning: '#FEECEB',
                            string: '#FA7921',
                            number: '#70CE35',
                            colon: '#49B8F7',
                            keys: '#59A5D8',
                            keys_whiteSpace: '#835FB6',
                            primitive: '#386FA4'
                        }
                    }
                    locale={locale}
                    height='200px'
                /></div>
        }

        if (dataField.type === 'checkbox') {
            return <FormControlLabel
                control={
                    <Switch
                        checked={this.props.data[dataField.identifier] ?? false}
                        onChange={this.onDataModelCheckboxChange}
                        name={dataField.identifier}
                    />
                }
                label={dataField.label}
            />
        }

        if (dataField.type === 'select') {

            const menu = Object.keys(dataField.options).map((key) => {
                const label = dataField.options[key];

                return <MenuItem key={key} value={key}>{label}</MenuItem>;
            });

            return <SimpleSelect
                required={dataField.required}
                key={dataField.identifier}
                value={this.props.data[dataField.identifier] ?? ''}
                onChange={this.onDataModelChange}
                name={dataField.identifier}
                label={dataField.label}
                className={classes.formControl}
                fullWidth
            >
                {menu}
            </SimpleSelect>
        }

        return <TextField
            required={dataField.required}
            key={dataField.identifier}
            fullWidth
            type={dataField.type}
            variant="outlined"
            label={dataField.label}
            value={this.props.data[dataField.identifier] ?? ''}
            name={dataField.identifier}
            onChange={this.onDataModelChange}
            className={classes.formControl}
        />
    };

    render() {
        const classes = this.props.classes;

        const dataFieldDescriptions = this.state.dataFields.map((dataField, i) => {
            return <Fragment key={dataField.identifier}>
                <ListItem key={dataField.identifier}>
                    <ListItemText
                        primary={<Fragment>{dataField.label} {dataField.required && <Chip color='secondary' size='small' label={this.props.t('required')}/>} </Fragment>}
                        secondary={<div
                            dangerouslySetInnerHTML={{__html: dataField.description || this.props.t('dataFields.noDescription')}}
                        />}
                    />
                </ListItem>
                {i + 1 < this.state.dataFields.length && <Divider variant="middle"/>}
            </Fragment>;
        });


        const dataFields = this.state.dataFields.map((dataField) => {
            return this.getDataField(dataField);
        });

        return <Fragment>
            <Button
                className={classes.moreInfoButton}
                size="small"
                onClick={this.openInfoDialog}
                startIcon={<HelpIcon/>}
            >
                {this.props.t('dataFields.dataFieldDescriptions')}
            </Button>
            {dataFields}
            <Dialog onClose={this.closeInfoDialog} open={this.state.infoDialogOpen}>
                <DialogTitle>{this.props.t('dataFields.dataFieldDescriptions')}</DialogTitle>
                <List>
                    {dataFieldDescriptions}
                </List>
                <DialogActions>
                    <Button onClick={this.closeInfoDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>


    }
}

export default compose(
    withStyles(useStyles),
    withTranslation())(GenericDataFields);