import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {ButtonLink} from '../Utilities/ButtonLink'
import {
    Box,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Toolbar,
    withWidth
} from "@material-ui/core";
import {Route, Switch} from "react-router";
import New from "./New";
import {connect} from "react-redux";
import Edit from "./Edit";
import IconButtonLink from "../Utilities/IconButtonLink";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Rest from "../../core/Rest";
import LoadingButton from "../Utilities/LoadingButton";
import {fetchNotificationsList} from "../../actions/notificationActions";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import SendIcon from '@material-ui/icons/Send'
import OutputTranslator from "../Utilities/OutputTranslator";

const useStyles = theme => ({
    notificationTitle: {
        fontSize: 20,
        textAlign: 'left'
    },
    grow: {
        flexGrow: 1,
    },
    createButton: {
        marginRight: theme.spacing(1)
    },
});

class Notifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: new Set(),
            deleteDialogOpen: false,
            isDeleting: false,
            isTesting: false
        };
    }

    componentDidMount() {
        this.props.actions.fetchNotificationsList();
    }

    openDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: true
        })
    };

    deletedSelectedNotifications = () => {
        this.setState({isDeleting: true});

        const promises = [];

        const selected = this.state.selected;

        this.state.selected.forEach((notificationId) => {
            promises.push(Rest.fetch({
                method: 'DELETE',
                endpoint: 'notifications/' + notificationId
            }).then(
                response => {
                    if (selected.has(notificationId)) {
                        selected.delete(notificationId);
                    }
                }
            ));

            Promise.all(promises).then(() => {
                this.setState({isDeleting: false});
                this.setState({selected: selected});
                this.closeDeleteDialog();
                this.props.actions.fetchNotificationsList();
            }, (err) => {
                console.log(err);
            })
        });
    };

    closeDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: false
        })
    };

    onCheckboxChange = (event) => {
        const id = event.target.value;
        const checked = event.target.checked;

        const selected = this.state.selected;

        if (selected.has(id) && !checked) {
            selected.delete(id);
        } else if (!selected.has(id) && checked) {
            selected.add(id);
        }
        this.setState({selected: selected})
    };

    render() {
        const match = this.props.match;
        const classes = this.props.classes;

        const notifications = this.props.notifications.map((notification, i) => {

            let maxlastSend = null;
            notification.watchNotifications.filter((watchNotification) => {
                return !!watchNotification.lastSend;
            }).forEach((watchNotification) => {
                if (!maxlastSend) {
                    maxlastSend = moment(watchNotification.lastSend);
                    return;
                }
                maxlastSend = moment.max(maxlastSend, moment(watchNotification.lastSend));
            });

            let lastSendChip = <Chip icon={<SendIcon/>} size='small' label={this.props.t('never')}/>;
            if (maxlastSend) {
                lastSendChip = <Chip icon={<SendIcon/>} color="secondary" size='small' label={maxlastSend.calendar()}/>;
            }

            return <Fragment key={i}>
                <ListItem alignItems="flex-start">
                    <ListItemIcon>
                        <Checkbox value={notification.id} checked={this.state.selected.has(notification.id)}
                                  onChange={this.onCheckboxChange}/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography className={classes.notificationTitle}>{notification.name} {lastSendChip}</Typography>}
                        secondary={<OutputTranslator output={notification.output} />}
                    />
                    <ListItemSecondaryAction>
                        <IconButtonLink edge="end" to={'/notifications/' + notification.id + '/edit'}>
                            <EditIcon/>
                        </IconButtonLink>
                    </ListItemSecondaryAction>
                </ListItem>
                {i + 1 < this.props.notifications.length && <Divider variant="inset" component="li"/>}
            </Fragment>
        });

        const list = notifications.length ? <List>
            {notifications}
        </List> : '';

        const content = <div>
            <Toolbar disableGutters={true}>
                <ButtonLink variant="contained" color="primary" to={'/notifications/new'}>
                    <AddIcon/> {this.props.t('notifications.create')}
                </ButtonLink>
                <div className={classes.grow}/>
                {!!this.state.selected.size && <Box justifyContent="flex-end">
                    <Button variant="contained" color="secondary" onClick={this.openDeleteDialog} className={classes.createButton}>
                        <DeleteIcon/> {this.props.t('notifications.delete')}
                    </Button>
                </Box>}
            </Toolbar>
            <Paper>
                {list}
            </Paper>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.deleteDialogOpen}
                onClose={this.closeDeleteDialog}
            >
                <DialogTitle>{this.props.t('deleteTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.t('notifications.deleteDescription')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.isDeleting} onClick={this.closeDeleteDialog} autoFocus variant='contained'>
                        {this.props.t('cancel')}
                    </Button>
                    <LoadingButton loading={this.state.isDeleting} onClick={this.deletedSelectedNotifications} color="secondary"
                                   variant='contained'>
                        {this.props.t('delete')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>;

        if (match.url === this.props.location.pathname) {
            return content;
        }

        const router = <Switch>
            <Route path={match.url + '/new'} component={New}/>
            <Route path={match.url + '/:id/edit'} component={Edit}/>
        </Switch>;

        if (['sm', 'xs'].indexOf(this.props.width) !== -1) {
            return router
        }

        return <Grid container spacing={2}>
            <Grid item sm={12} md={6} lg={8}>
                {content}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
                {router}
            </Grid>
        </Grid>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchNotificationsList}, dispatch),
    }
};

function mapStateToProps(state) {

    const notifications = state.notifications.list || [];

    return {notifications}
}

export default compose(
    withStyles(useStyles),
    withWidth(),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Notifications);