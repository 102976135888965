import {WATCHES_LIST_FAILURE, WATCHES_LIST_REQUEST, WATCHES_LIST_SUCCESS} from "../actions/watchActions";

export function watchReducer(state = {
    isFetching: false,
    list: []
}, action) {
    switch (action.type) {
        case WATCHES_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case WATCHES_LIST_FAILURE:

            let error = null;
            try {
                error = JSON.parse(action.error);
            } catch (e) {
                error = action.error;
            }

            return Object.assign({}, state, {
                error: error,
                isFetching: false,
            });
        case WATCHES_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                list: action.response
            });
        default:
            return state
    }
}

