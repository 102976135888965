import React, {Component} from 'react';
import {bindActionCreators, compose} from "redux";
import {Paper, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import qs from "query-string";
import {push} from "connected-react-router";
import Rest from "../../core/Rest";
import {fetchUser} from "../../actions/userActions";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
});


class Payment extends Component {

    intervalId;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const params = qs.parse(this.props.location.search);

        if (params.subscription_id) {
            this.setState({
                subscriptionId: params.subscription_id
            }, () => {
                this.checkStatus();
                this.intervalId = setInterval(() => {
                    this.checkStatus();
                }, 10000)
            });
        } else {
            this.props.redirectToProfile();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user.isFetching && !this.props.user.isFetching) {
            this.props.redirectToProfile();
        }
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }

    checkStatus = () => {
        Rest.fetch({
            method: 'POST',
            endpoint: 'billing/subscriptions/' + this.state.subscriptionId + '/check'
        }).then(
            response => {
                if (response.response.status === 'ACTIVE') {
                    this.props.actions.fetchUser();
                }
            },
            e => null
        );
    };

    render() {
        const classes = this.props.classes;

        return <Paper className={classes.root}>
            <CircularProgress/>
            <Typography variant='subtitle1'>{this.props.t('billing.waitForActive')}</Typography>
        </Paper>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchUser}, dispatch),
        redirectToProfile: () => {
            dispatch(push('/profile'))
        }
    }
};

function mapStateToProps(state) {
    const {user} = state;
    return {user}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Payment);