import {Component} from 'react';
import {connect} from "react-redux";

class OutputTranslator extends Component {
    render() {
        const output = this.props.outputs.find((output) => {
            return output.identifier === this.props.output
        });

        if (output) {
            return output.name
        }

        return this.props.output
    }
}

function mapStateToProps(state) {
    const outputs = state.outputs.list || [];
    return {outputs}
}

export default connect(mapStateToProps, null)(OutputTranslator)