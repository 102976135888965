import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {loginUser, logoutUser} from '../../actions/sessionActions';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from "react-i18next";
import qs from 'query-string';
import LoadingScreen from "../Utilities/LoadingScreen";

const useStyles = theme => ({});

class CognitoLogin extends Component {

    checkCognitoParams = () => {

        const params = qs.parse(this.props.location.search);

        if (params.code && params.code.length) {
           this.loginUserCognito(params.code);
        } else {
            window.location.replace(process.env.REACT_APP_COGNITO_URL + '/login?client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&response_type=code&scope=email+openid&redirect_uri=' + encodeURI(process.env.REACT_APP_COGNITO_REDIRECT))
        }
    };

    loginUserCognito = (code) => {
        this.props.actions.loginUser({
            'code': code
        }, '/');
    };

    componentDidMount() {
        this.props.actions.logoutUser();
        this.checkCognitoParams();
    }


    render() {
        return <LoadingScreen isFetching={this.props.auth.isFetching}/>
    }
}


function mapStateToProps(state) {
    const {auth} = state;

    return {auth}
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({loginUser, logoutUser}, dispatch),
    }
};

export default compose(
    withStyles(useStyles),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps))(CognitoLogin);