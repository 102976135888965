import {CALL_API} from '../middleware/api'

export const LIST_BILLING_PLANS_REQUEST = 'LIST_BILLING_PLANS_REQUEST';
export const LIST_BILLING_PLANS_SUCCESS = 'LIST_BILLING_PLANS_SUCCESS';
export const LIST_BILLING_PLANS_FAILURE = 'LIST_BILLING_PLANS_FAILURE';

export function fetchBillingPlanList() {

    return {
        [CALL_API]: {
            endpoint: 'billing/plans',
            method: 'GET',
            types: [LIST_BILLING_PLANS_SUCCESS, LIST_BILLING_PLANS_FAILURE, LIST_BILLING_PLANS_REQUEST]
        }
    }
}

