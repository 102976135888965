import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    Toolbar
} from '@material-ui/core'
import SimpleSelect from "../Utilities/SimpleSelect";
import LoadingButton from "../Utilities/LoadingButton";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import Rest from "../../core/Rest";
import {ButtonLink} from "../Utilities/ButtonLink";
import CloseIcon from "@material-ui/icons/Close";
import {fetchWatchesList} from "../../actions/watchActions";
import HelpIcon from '@material-ui/icons/Help';

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formControl: {
        paddingBottom: theme.spacing(1)
    },
    grow: {
        flexGrow: 1,
    },
    moreInfoButton: {
        color: theme.palette.text.secondary
    }
});

class New extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            inputType: '',
            name: '',
            infoDialogOpen: false
        }
    }

    openInfoDialog = () => {
        this.setState({
            infoDialogOpen: true
        })
    };

    closeInfoDialog = () => {
        this.setState({
            infoDialogOpen: false
        })
    };

    onModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const state = this.state;
        state[name] = value;

        this.setState(state);
    };

    saveWatch = (e) => {
        e.preventDefault();

        this.setState({isSaving: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'watches',
            body: {
                name: this.state.name,
                input: this.state.inputType
            }
        }).then(
            response => {
                this.props.actions.fetchWatchesList();
                this.props.openWatch(response.response.id);
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    render() {
        const classes = this.props.classes;

        const menuInputs = this.props.inputs.map((input) => {
            return <MenuItem key={input.identifier} value={input.identifier}>{input.name}</MenuItem>;
        });

        const inputTypeDescriptions = this.props.inputs.map((input, i) => {
            return <Fragment key={input.identifier}><ListItem key={input.identifier}>
                <ListItemText primary={input.name} secondary={<div dangerouslySetInnerHTML={{__html: input.description}}/>}/>
            </ListItem>
                {i + 1 < this.props.inputs.length && <Divider variant="middle"/>}
            </Fragment>;
        });

        return <div>
            <Toolbar disableGutters={true}>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <ButtonLink variant="contained" color="primary" to={'/'}>
                        <CloseIcon/> {this.props.t('close')}
                    </ButtonLink>
                </Box>
            </Toolbar>
            <Paper className={classes.paper}>
                <form noValidate>
                    <LoadingButton variant='contained' color='primary' type='submit' loading={this.state.isSaving} onClick={this.saveWatch}>
                        <SaveIcon/> {this.props.t('save')}
                    </LoadingButton>
                    <TextField label={this.props.t('name')}
                               className={classes.formControl}
                               variant="outlined"
                               margin="normal"
                               name='name'
                               value={this.state.name}
                               onChange={this.onModelChange}
                               required
                               fullWidth
                    />
                    <SimpleSelect value={this.state.inputType} onChange={this.onModelChange} name='inputType'
                                  label={this.props.t('watches.inputType')} className={classes.formControl} fullWidth required>
                        {menuInputs}
                    </SimpleSelect>
                    <Button
                        className={classes.moreInfoButton}
                        size="small"
                        onClick={this.openInfoDialog}
                        startIcon={<HelpIcon/>}
                    >
                        {this.props.t('watches.moreInputTypeInfo')}
                    </Button>
                </form>
            </Paper>
            <Dialog onClose={this.closeInfoDialog} open={this.state.infoDialogOpen}>
                <DialogTitle>{this.props.t('watches.moreInputTypeInfo')}</DialogTitle>
                <List>
                    {inputTypeDescriptions}
                </List>
                <DialogActions>
                    <Button onClick={this.closeInfoDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchWatchesList}, dispatch),
        openWatch: (id) => dispatch(push('/watches/' + id + '/edit'))
    }
};

function mapStateToProps(state) {
    const inputs = state.inputs.list || [];
    return {inputs}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(New);