import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from "redux";
import {Divider, Grid, ListItem, ListItemText, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Rest from "../../../core/Rest";
import LinearProgress from "@material-ui/core/LinearProgress";
import {fetchBillingPlanList} from "../../../actions/billingActions";
import List from "@material-ui/core/List";
import {getPlanDetails} from "../../../service/BillingPlans";
import ButtonLink from "../../Utilities/ButtonLink";
import Button from "@material-ui/core/Button";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(2),
    },
    changePlanButton: {
        marginBottom: theme.spacing(1)
    }
});

const BorderLinearProgress = withStyles({
    root: {
        height: 8,
    },

})(LinearProgress);

class Subscription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            subscriptions: [],
            isSaving: false
        }
    }

    componentDidMount() {
        this.props.actions.fetchBillingPlanList();
        this.fetchSubscriptions();
    }

    fetchSubscriptions = () => {
        Rest.fetch({
            method: 'GET',
            endpoint: 'billing/subscriptions',
        }).then(
            response => {
                this.setState({
                    subscriptions: response.response,
                })
            },
            error => {
            }
        );
    };

    render() {
        const classes = this.props.classes;

        let currentUsage = 0;
        if (this.props.user.userObject.maximumExecutions > 0) {
            currentUsage = this.props.user.userObject.executions / this.props.user.userObject.maximumExecutions * 100;
        }

        let subscriptionList = [];

        if(!this.props.plans.isFetching) {
            subscriptionList = this.state.subscriptions.map((subscription, i) => {

                const plan = this.props.plans.list ? this.props.plans.list.find((plan) => {
                    return plan.identifier === subscription.planId.replace('-no-trial', '')
                }) : {};

                const details = getPlanDetails(plan.identifier);

                let suffix = '';
                if (subscription.additionalExecutions > 0) {
                    suffix = ' with ' + subscription.additionalExecutions * 10000 + ' additional executions'
                }

                return <Fragment key={subscription.id}>
                    <ListItem key={subscription.id}>
                        <ListItemText
                            primary={plan.label + suffix}
                            secondary={details.price + subscription.additionalExecutions + '€/month'}
                        />
                    </ListItem>
                    {i + 1 < this.state.subscriptions.length && <Divider/>}
                </Fragment>;
            });
        }

        return <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={6}>
                    <h2>Active Subscriptions</h2>
                    <List disablePadding={true}>
                        {subscriptionList}
                    </List>
                    <ButtonLink className={classes.changePlanButton} fullWidth color='primary' variant='outlined'>
                        {this.props.t('billing.changePlan')}
                    </ButtonLink>
                    <Button fullWidth color='secondary' variant='outlined'>
                        {this.props.t('billing.cancel')}
                    </Button>
                    <h2>Current Usage</h2>
                    {this.props.user.userObject.executions } / {this.props.user.userObject.maximumExecutions} {this.props.t('currentExecutions')}
                    <BorderLinearProgress value={currentUsage} variant='determinate'>
                        {currentUsage}
                    </BorderLinearProgress>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                    <h2>Recent Invoices</h2>
                </Grid>
            </Grid>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchBillingPlanList}, dispatch),
    }
};

function mapStateToProps(state) {
    const {plans, user} = state;
    return {plans, user}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Subscription);