import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n/i18n";
import "react-vis/dist/style.css";

i18n.changeLanguage('en');


ReactDOM.render(<I18nextProvider i18n={i18n}>
    <App/>
</I18nextProvider>, document.getElementById('root'));
