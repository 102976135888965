import {Component} from 'react';
import {connect} from "react-redux";

class InputTranslator extends Component {
    render() {
        const input = this.props.inputs.find((input) => {
            return input.identifier === this.props.input
        });

        if (input) {
            return input.name
        }

        return this.props.input
    }
}

function mapStateToProps(state) {
    const inputs = state.inputs.list || [];
    return {inputs}
}

export default connect(mapStateToProps, null)(InputTranslator)