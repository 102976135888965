import React from 'react';
import {push} from "connected-react-router";
import store from './store';
import ValidationMessages from "../service/ValidationMessages";

const BASE_URL = '/api/';

export default class Rest {

    // Now you can write your own methods easily
    static fetch(options) {

        const {endpoint, method = 'GET', parameter = {}, header = {}, body, auth = true, ignoreErrorCodes = []} = options;

        const token = localStorage.getItem('token') || null;

        let config = {
            method: method
        };

        if (auth && token) {
            header.Authorization = `Bearer ${token}`
        } else if (auth) {
            store.dispatch(push('/login'));
        }


        header['Accept'] = 'application/json';

        if (typeof body !== 'undefined') {
            config.body = JSON.stringify(body);
            header['Content-Type'] = 'application/json';
        }

        config.headers = header;

        let params = '';
        if (Object.keys(parameter).length) {
            for (const key in parameter) {
                if (typeof parameter[key] !== 'object') {
                    continue;
                }

                for (const objectKey in parameter[key]) {
                    if (typeof parameter[key][objectKey] === 'undefined') {
                        continue;
                    }

                    parameter[key + '[' + objectKey + ']'] = parameter[key][objectKey];
                }

                delete parameter[key];
            }

            params = '?' + (new URLSearchParams(parameter));
        }

        store.dispatch({
            type: 'ADD_REQUEST'
        });

        const prefix = process.env.REACT_APP_API || '';

        return fetch(prefix + BASE_URL + endpoint + params, config)
            .then(response => {
                return response.json().then((text) => ({text, response}), (e) => {
                    store.dispatch({
                        type: 'ADD_ALERT',
                        message: e.message,
                        style: 'error'
                    });

                    store.dispatch({
                        type: 'REMOVE_REQUEST'
                    });

                    return Promise.reject({
                        message: e.message,
                        code: response.status,
                        header: response.headers
                    })
                })
            }).then(({text, response}) => {

                store.dispatch({
                    type: 'REMOVE_REQUEST'
                });

                if (response.status === 401 && auth) {
                    store.dispatch(push('/login'));
                } else if(!response.ok){

                    let errorText = text.message;

                    if (text.error && text.error.message) {
                        errorText = text.error.message;
                    }

                    if (response.status === 400 && text.error && text.error.validation_errors) {

                        try {
                            errorText = <div>
                                <b>Invalid Data.</b>
                                <ul style={{paddingLeft: '1.5em', marginBottom: 0, marginTop: 0}}>
                                    <ValidationMessages validationErrors={text.error.validation_errors}/>
                                </ul>
                            </div>
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    if (!errorText) {
                        errorText = "An error occurred";
                    }

                    if(ignoreErrorCodes.indexOf(response.status) === -1) {
                        store.dispatch({
                            type: 'ADD_ALERT',
                            message: errorText,
                            style: 'error'
                        });
                    }

                    return Promise.reject({
                        message: errorText,
                        raw: text,
                        code: response.status,
                        header: response.headers
                    })
                }

                return {
                    response: text,
                    header: response.headers
                }
            })
    }


};