import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save'
import {Box, MenuItem, Paper, TextField, Toolbar} from '@material-ui/core'
import SimpleSelect from "../Utilities/SimpleSelect";
import LoadingButton from "../Utilities/LoadingButton";
import {connect} from "react-redux";
import Rest from "../../core/Rest";
import {ButtonLink} from "../Utilities/ButtonLink";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {fetchWatchesList} from "../../actions/watchActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import GenericDataFields from "../DataFields/GenericDataFields";

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    formControl: {
        paddingBottom: theme.spacing(1)
    },
    divider: {
        marginBottom: theme.spacing(1)
    },
    saveButton: {
        marginRight: theme.spacing(1)
    },
    grow: {
        flexGrow: 1,
    }
});

class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            isTesting: false,
            isSaving: false,
            inputType: '',
            name: '',
            executionInterval: 60,
            active: false,
            data: {}
        }
    }

    componentDidMount() {
        this.fetchWatch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchWatch();
        }
    }

    onModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        this.setState({...this.state, [name]: value});
    };

    onModelCheckboxChange = (event) => {
        this.setState({...this.state, [event.target.name]: event.target.checked});
    };

    onDataChange = (name, value) => {
        const data = this.state.data;
        data[name] = value;
        this.setState({data: data});
    };

    saveWatch = (e) => {
        e.preventDefault();

        this.setState({isSaving: true});

        const data = Object.keys(this.state.data).map((identifier) => {
            return {
                identifier: identifier,
                value: this.state.data[identifier]
            }
        });

        Rest.fetch({
            method: 'PUT',
            endpoint: 'watches/' + this.props.match.params.id,
            body: {
                name: this.state.name,
                data: data,
                active: this.state.active,
                executionInterval: this.state.executionInterval
            }
        }).then(
            response => {
                this.props.actions.fetchWatchesList();
            },
            e => null
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    testWatch = (e) => {
        e.preventDefault();

        this.setState({isTesting: true});

        Rest.fetch({
            method: 'POST',
            endpoint: 'watches/' + this.props.match.params.id + '/test'
        }).then(
            response => {

                let message = 'Watch would be on alert. ';
                if (!response.response.isAlerting) {
                    message = 'Watch would not be on alert. ';
                }

                this.props.sendNotification('Test done. ' + message + 'Current value: ' + response.response.value, 'success')
            },
            e => null
        ).then(() => {
            this.setState({isTesting: false});
        });
    };

    fetchWatch = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'watches/' + this.props.match.params.id,
        }).then(
            response => {
                const data = {};
                response.response.data.forEach((entry) => {
                    data[entry.identifier] = entry.value
                });

                this.setState({
                    name: response.response.name,
                    inputType: response.response.input,
                    data: data,
                    active: response.response.active,
                    executionInterval: response.response.executionInterval
                })
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    };

    render() {
        const classes = this.props.classes;

        const menuInputs = this.props.inputs.map((input) => {
            return <MenuItem key={input.identifier} value={input.identifier}>{input.name}</MenuItem>;
        });

        return <div>
            <Toolbar disableGutters={true}>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <ButtonLink variant="contained" color="primary" to={'/'}>
                        <CloseIcon/> {this.props.t('close')}
                    </ButtonLink>
                </Box>
            </Toolbar>
            <Paper className={classes.paper}>
                <form noValidate>
                    <Toolbar disableGutters={true} variant='dense'>
                        <LoadingButton className={classes.saveButton} variant='contained' color='primary' type='submit'
                                       loading={this.state.isSaving}
                                       onClick={this.saveWatch}
                                       disabled={this.state.isFetching}>
                            <SaveIcon/> {this.props.t('save')}
                        </LoadingButton>
                        <LoadingButton variant='outlined' loading={this.state.isTesting} onClick={this.testWatch}
                                       disabled={this.state.isFetching || this.state.isSaving}>
                            <DoneIcon/> {this.props.t('test')}
                        </LoadingButton>
                    </Toolbar>
                    <TextField label={this.props.t('name')}
                               className={classes.formControl}
                               variant="outlined"
                               margin="normal"
                               name='name'
                               value={this.state.name}
                               onChange={this.onModelChange}
                               required
                               fullWidth
                    />
                    <SimpleSelect value={this.state.inputType} onChange={this.onModelChange} name='inputType'
                                  label={this.props.t('watches.inputType')} className={classes.formControl} disabled fullWidth required>
                        {menuInputs}
                    </SimpleSelect>
                    <SimpleSelect value={this.state.executionInterval} onChange={this.onModelChange} name='executionInterval'
                                  label={this.props.t('watches.executionInterval')} className={classes.formControl}  fullWidth required>
                        <MenuItem key={60} value={60}>{this.props.t('interval.minute')}</MenuItem>;
                        <MenuItem key={300} value={300}>{this.props.t('interval.5minute')}</MenuItem>;
                        <MenuItem key={900} value={900}>{this.props.t('interval.15minute')}</MenuItem>;
                        <MenuItem key={1800} value={1800}>{this.props.t('interval.30minute')}</MenuItem>;
                        <MenuItem key={3600} value={3600}>{this.props.t('interval.hour')}</MenuItem>;
                        <MenuItem key={10800} value={10800}>{this.props.t('interval.6hour')}</MenuItem>;
                        <MenuItem key={21600} value={21600}>{this.props.t('interval.12hour')}</MenuItem>;
                        <MenuItem key={43200} value={43200}>{this.props.t('interval.daily')}</MenuItem>;
                    </SimpleSelect>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.active}
                                onChange={this.onModelCheckboxChange}
                                name='active'

                            />
                        }
                        label={this.props.t('active')}
                    />
                    {this.state.inputType && <Fragment>
                        <Divider className={classes.divider}/>
                        <GenericDataFields type='inputs' identifier={this.state.inputType} onChange={this.onDataChange}
                                           data={this.state.data}/>
                    </Fragment>}
                </form>
            </Paper>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchWatchesList}, dispatch),
        sendNotification: (message, style) => {
            dispatch({
                type: 'ADD_ALERT',
                message: message,
                style: style
            });
        }
    }
};

function mapStateToProps(state) {
    const inputs = state.inputs.list || [];
    return {inputs}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Edit);