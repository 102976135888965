import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {fetchWatchesList} from "../../../actions/watchActions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from '@material-ui/icons/Save'
import Rest from "../../../core/Rest";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingButton from "../../Utilities/LoadingButton";
import OutputTranslator from "../../Utilities/OutputTranslator";

const useStyles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    variablesPaper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1)
    },
    expansionPane: {
        display: 'block !important'
    },
    grow: {
        flexGrow: 1,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});

class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            message: props.notification.message || '',
            recoverMessage: props.notification.recoverMessage || '',
            deleteDialogOpen: false
        }
    }


    onDataModelChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        this.setState({...this.state, [name]: value});
    };

    openDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: true
        })
    };

    closeDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: false
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.notification.message !== prevProps.notification.message) {
            this.setState({message: this.props.notification.message})
        }

        if (this.props.notification.recoverMessage !== prevProps.notification.recoverMessage) {
            this.setState({recoverMessage: this.props.notification.recoverMessage})
        }
    }

    deleteNotification = () => {

        this.setState({isDeleting: true});

        Rest.fetch({
            method: 'DELETE',
            endpoint: 'watch_notifications/' + this.props.notification.id,
        }).then(
            response => {
                this.props.onDelete();
            },
            error => {
            }
        ).then(() => {
            this.closeDeleteDialog();
            this.setState({isDeleting: false});
        });
    };

    saveNotification = () => {

        this.setState({isSaving: true});

        Rest.fetch({
            method: 'PUT',
            endpoint: 'watch_notifications/' + this.props.notification.id,
            body: {
                message: this.state.message,
                recoverMessage: this.state.recoverMessage
            }
        }).then(
            response => {
                this.props.onSave();
            },
            error => {
            }
        ).then(() => {
            this.setState({isSaving: false});
        });
    };

    render() {

        const input = this.props.inputs.find((input) => {
            return input.identifier === this.props.notification.watch.input
        });

        let variables = [];
        if (input) {
            variables = Object.keys(input.variables).map((variable) => {
                return <li>{variable}: {input.variables[variable]} </li>
            });
        }

        const classes = this.props.classes;

        return <Fragment>
            <ExpansionPanel expanded={this.props.open} onChange={(event, open) => {
                this.props.setOpen(open)
            }}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Typography className={classes.heading}>{this.props.notification.notification.name}</Typography>
                    <Typography className={classes.secondaryHeading}><OutputTranslator
                        output={this.props.notification.notification.output}/></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPane}>
                    <div className={classes.variablesPaper}>
                        <Typography variant='subtitle1'>Available Variables:</Typography>
                        <ul>
                            <li>link: The link to this watch</li>
                            <li>value: The value of the alert</li>
                            <li>time: Alerting time string</li>
                            {variables}
                        </ul>
                        Usage: {'{{variable}}'}
                    </div>
                    <TextField
                        onChange={this.onDataModelChange}
                        value={this.state.message}
                        name='message'
                        label={this.props.t('watchNotification.message')}
                        helperText='The message send by the notification if watch is alerting'
                        variant='outlined'
                        multiline={true}
                        margin="normal"
                        rows={2}
                        rowsMax={4}
                        fullWidth
                    />
                    <TextField
                        onChange={this.onDataModelChange}
                        value={this.state.recoverMessage}
                        name='recoverMessage'
                        label={this.props.t('watchNotification.recoverMessage')}
                        helperText='The message send by the notification if watch is recovered'
                        variant='outlined'
                        multiline={true}
                        margin="normal"
                        rows={2}
                        rowsMax={4}
                        fullWidth
                    />
                </ExpansionPanelDetails>
                <ExpansionPanelSummary>
                    <Button disabled={this.state.isSaving} variant="contained" color="secondary" onClick={this.openDeleteDialog}>
                        <DeleteIcon/> {this.props.t('remove')}
                    </Button>
                    <div className={classes.grow}/>
                    <LoadingButton loading={this.state.isSaving} variant="contained" color="primary" onClick={this.saveNotification}>
                        <SaveIcon/> {this.props.t('save')}
                    </LoadingButton>
                </ExpansionPanelSummary>
            </ExpansionPanel>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.deleteDialogOpen}
                onClose={this.closeDeleteDialog}
            >
                <DialogTitle>{this.props.t('deleteTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.t('watchNotifications.deleteDescription')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.isDeleting} onClick={this.closeDeleteDialog} autoFocus variant='contained'>
                        {this.props.t('cancel')}
                    </Button>
                    <LoadingButton loading={this.state.isDeleting} onClick={this.deleteNotification} color="secondary"
                                   variant='contained'>
                        {this.props.t('delete')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        actions: bindActionCreators({fetchWatchesList}, dispatch)
    }
};

function mapStateToProps(state) {
    const inputs = state.inputs.list || [];
    return {inputs}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Notification);