import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import authReducer from "../reducers/authReducer";
import {inputReducer} from "../reducers/inputReducer";
import {outputReducer} from "../reducers/outputReducer";
import {requestCountReducer} from "../reducers/requestCountReducer";
import {watchReducer} from "../reducers/watchReducer";
import {notificationReducer} from "../reducers/notificationReducer";
import alertReducer from "../reducers/alertReducer";
import {billingPlansReducer} from "../reducers/billingReducer";
import {userReducer} from "../reducers/userReducer";

export default (history) => combineReducers({
    auth: authReducer,
    alerts: alertReducer,
    inputs: inputReducer,
    outputs: outputReducer,
    requestCount: requestCountReducer,
    watches: watchReducer,
    notifications: notificationReducer,
    plans: billingPlansReducer,
    router: connectRouter(history),
    user: userReducer
});