export const PLANS = {
    'starter': {
        'label': 'Starter',
        'price': 3,
        'minimumInterval': 15,
        'hasMetrics': false,
        'executions': 30000,
        'hasQuantity': false
    },
    'extended': {
        'label': 'Extended',
        'price': 12,
        'minimumInterval': 5,
        'hasMetrics': true,
        'executions': 120000,
        'hasQuantity': false
    },
    'pro': {
        'label': 'Pro',
        'price': 30,
        'minimumInterval': 1,
        'hasMetrics': true,
        'executions': 450000,
        'hasQuantity': false
    }
};

export const getPlanDetails = (plan) => {
    return PLANS[plan] || null
};
