import React, {Component} from 'react';
import {Route, Switch} from "react-router"
import Watches from "./Watches";
import Metrics from "./Metrics/Metrics";


class Router extends Component {

    render() {

        const match = this.props.match;


        return <Switch>
            <Route path={match.url + '/:id/metrics'} component={Metrics}/>
            <Route path={match.url} component={Watches}/>
        </Switch>

    }
}

export default Router