import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import {ButtonLink} from '../Utilities/ButtonLink'
import {
    Avatar,
    Box,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Toolbar,
    withWidth
} from "@material-ui/core";
import {Route, Switch} from "react-router";
import New from "./New";
import {connect} from "react-redux";
import {fetchWatchesList} from "../../actions/watchActions";
import Chip from "@material-ui/core/Chip";
import Edit from "./Edit";
import IconButtonLink from "../Utilities/IconButtonLink";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Rest from "../../core/Rest";
import LoadingButton from "../Utilities/LoadingButton";
import Notifications from "./Notifications/Notifications";
import moment from "moment";
import InputTranslator from "../Utilities/InputTranslator";
import CheckIcon from '@material-ui/icons/Check'
import ChartIcon from '@material-ui/icons/Timeline'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

const useStyles = theme => ({
    watchTitle: {
        fontSize: 20,
        textAlign: 'left'
    },
    grow: {
        flexGrow: 1,
    },
    alerting: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
    },
    notAlerting: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
    },
    createButton: {
        marginRight: theme.spacing(1)
    },
});

class Watches extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: new Set(),
            deleteDialogOpen: false,
            isDeleting: false
        };
    }

    componentDidMount() {
        this.props.actions.fetchWatchesList();
    }

    openDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: true
        })
    };

    deletedSelectedWatches = () => {
        this.setState({isDeleting: true});

        const promises = [];

        const selected = this.state.selected;

        this.state.selected.forEach((watchId) => {
            promises.push(Rest.fetch({
                method: 'DELETE',
                endpoint: 'watches/' + watchId
            }).then(
                response => {
                    if (selected.has(watchId)) {
                        selected.delete(watchId);
                    }
                }
            ));

            Promise.all(promises).then(() => {
                this.setState({isDeleting: false});
                this.setState({selected: selected});
                this.closeDeleteDialog();
                this.props.actions.fetchWatchesList();
            }, (err) => {
                console.log(err);
            })
        });
    };

    closeDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: false
        })
    };

    onCheckboxChange = (event) => {
        const id = event.target.value;
        const checked = event.target.checked;

        const selected = this.state.selected;

        if (selected.has(id) && !checked) {
            selected.delete(id);
        } else if (!selected.has(id) && checked) {
            selected.add(id);
        }
        this.setState({selected: selected})
    };

    render() {
        const match = this.props.match;
        const classes = this.props.classes;

        const watches = this.props.watches.map((watch, i) => {


            let lastCheckChip = <Chip icon={<AccessAlarmIcon/>} size='small' label={this.props.t('never')}/>;
            if (watch.lastCheck) {
                const momentLastCheck = moment(watch.lastCheck);
                lastCheckChip = <Chip icon={<AccessAlarmIcon/>} color="secondary" size='small' label={momentLastCheck.calendar()}/>;
            }

            let chip = <Chip icon={<CancelIcon/>} size='small' label={this.props.t('disabled')}/>;
            if (watch.active) {
                chip = <Chip icon={<CheckCircleIcon/>} size='small' label={this.props.t('enabled')} color="secondary"/>;
            }

            let avatar = <Avatar className={classes.notAlerting}>
                <CheckIcon/>
            </Avatar>;
            if (watch.isAlerting) {
                avatar = <Avatar className={classes.alerting}>
                    <NotificationImportantIcon/>
                </Avatar>
            }

            return <Fragment key={i}>
                <ListItem alignItems="flex-start">
                    <ListItemIcon>
                        <Checkbox value={watch.id} checked={this.state.selected.has(watch.id)} onChange={this.onCheckboxChange}/>
                    </ListItemIcon>
                    <ListItemAvatar>
                        {avatar}
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography component='div'
                                             className={classes.watchTitle}>{watch.name} {chip} {lastCheckChip}</Typography>}
                        secondary={<InputTranslator input={watch.input}/>}
                    />
                    <ListItemSecondaryAction>
                        <IconButtonLink edge="end" to={'/watches/' + watch.id + '/metrics'}>
                            <ChartIcon/>
                        </IconButtonLink>
                        <IconButtonLink edge="end" to={'/watches/' + watch.id + '/edit'}>
                            <EditIcon/>
                        </IconButtonLink>
                        <IconButtonLink edge="end" to={'/watches/' + watch.id + '/notifications'}>
                            <AddAlertIcon/>
                        </IconButtonLink>
                    </ListItemSecondaryAction>
                </ListItem>
                {i + 1 < this.props.watches.length && <Divider variant="inset" component="li"/>}
            </Fragment>
        });

        const list = watches.length ? <List>
            {watches}
        </List> : '';

        const content = <div>
            <Toolbar disableGutters={true}>
                <ButtonLink variant="contained" color="primary" to={'/watches/new'} className={classes.createButton}>
                    <AddIcon/> {this.props.t('watches.create')}
                </ButtonLink>
                <div className={classes.grow}/>
                {!!this.state.selected.size && <Box justifyContent="flex-end">
                    <Button variant="contained" color="secondary" onClick={this.openDeleteDialog}>
                        <DeleteIcon/> {this.props.t('watches.delete')}
                    </Button>
                </Box>}
            </Toolbar>
            <Paper>
                {list}
            </Paper>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.deleteDialogOpen}
                onClose={this.closeDeleteDialog}
            >
                <DialogTitle>{this.props.t('deleteTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.t('watches.deleteDescription')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={this.state.isDeleting} onClick={this.closeDeleteDialog} autoFocus variant='contained'>
                        {this.props.t('cancel')}
                    </Button>
                    <LoadingButton loading={this.state.isDeleting} onClick={this.deletedSelectedWatches} color="secondary"
                                   variant='contained'>
                        {this.props.t('delete')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>;

        if (match.url === this.props.location.pathname) {
            return content;
        }

        const router = <Switch>
            <Route path={match.url + '/new'} component={New}/>
            <Route path={match.url + '/:id/edit'} component={Edit}/>
            <Route path={match.url + '/:id/notifications'} component={Notifications}/>
        </Switch>;

        if (['sm', 'xs'].indexOf(this.props.width) !== -1) {
            return router
        }

        return <Grid container spacing={2}>
            <Grid item sm={12} md={6} lg={8}>
                {content}
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
                {router}
            </Grid>
        </Grid>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchWatchesList}, dispatch),
    }
};

function mapStateToProps(state) {

    const watches = state.watches.list || [];

    return {watches}
}

export default compose(
    withStyles(useStyles),
    withWidth(),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Watches);