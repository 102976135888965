import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.contrastText,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const LoadingButton = (props) => {

    const classes = useStyles();

    const loading = props.loading;

    return <div className={classes.root}>
        <div className={classes.wrapper}>
            <Button
                {...props}
                disabled={loading || props.disabled}
            >
                {props.children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    </div>;
};

export default LoadingButton;