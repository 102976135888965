import React, {Component, Fragment} from 'react';
import {bindActionCreators, compose} from "redux";
import {CardHeader, Checkbox, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Rest from "../../../core/Rest";
import Grid from "@material-ui/core/Grid";
import {getPlanDetails} from "../../../service/BillingPlans";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(2),
    },
    card: {
        border: '2px solid',
        borderColor: theme.palette.text.primary
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    summaryPaper: {
        marginBottom: theme.spacing(2),
    },
    secondCard: {
        marginTop: theme.spacing(2),
    },
    trialInformation: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        border: '2px solid',
        borderColor: theme.palette.success.main
    },
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
});


class Order extends Component {

    constructor(props) {
        super(props);

        this.state = {
            countryIsFetching: false,
            addressIsFetching: false,
            countries: [],
            address: {},
            isSaving: false,
        }
    }

    componentDidMount() {
        this.props.setIsFetching(false);
        this.fetchCountries();
        this.fetchAddress();
        this.props.registerNextCallback(this.buy);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (
            prevState.countryIsFetching !== this.state.countryIsFetching ||
            prevState.addressIsFetching !== this.state.addressIsFetching
        ) {
            if (this.state.countryIsFetching || this.state.addressIsFetching) {
                this.props.setIsFetching(true);
            } else {
                this.props.setIsFetching(false);
            }
        }
    }

    fetchCountries = () => {
        this.setState({
            countryIsFetching: true
        });

        Rest.fetch({
            method: 'GET',
            endpoint: 'billing/countries',
        }).then(
            response => {
                this.setState({
                    countries: response.response,
                })
            },
            error => {
            }
        ).then(() => {
            this.setState({
                countryIsFetching: false
            });
        });
    };

    buy = () => {
        return new Promise((resolve, reject) => {

                let planId = this.props.selectedPlan;

                if (this.props.user.userObject.hadTrial) {
                    planId += '-no-trial'
                }

                const body = {
                    planId: planId,
                };

                if (this.props.executionPackages > 0) {
                    body.additionalExecutions = this.props.executionPackages
                }

                Rest.fetch({
                    method: 'POST',
                    endpoint: 'billing/subscriptions',
                    body: body
                }).then(
                    response => {
                        resolve();
                        window.location.replace(response.response.approveUrl)
                    },
                    error => {
                        reject();
                    }
                );
        });
    };

    fetchAddress = () => {
        this.setState({
            addressIsFetching: true
        });

        Rest.fetch({
            method: 'GET',
            endpoint: 'billing/address',
        }).then(
            response => {
                this.setState({
                    address: response.response
                });
            },
            error => {
            }
        ).then(() => {
            this.setState({
                addressIsFetching: false
            });
        });
    };


    render() {
        const classes = this.props.classes;

        const address = this.state.address || {};

        const country = this.state.countries.find((country) => {
            return country.id === address.country
        });

        const planKey = this.props.selectedPlan;
        const plan = getPlanDetails(planKey) || {};

        const rows = [];

        rows.push(<TableRow key={1}>
            <TableCell component="th" scope="row">
                {plan.label}
            </TableCell>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">{plan.price}€</TableCell>
            <TableCell align="right">{plan.price}€</TableCell>
        </TableRow>);

        if (this.props.executionPackages > 0) {
            rows.push(<TableRow key={2}>
                <TableCell component="th" scope="row">
                    {this.props.t('billing.additionalExecutions')}
                </TableCell>
                <TableCell align="right">{this.props.executionPackages}</TableCell>
                <TableCell align="right">1€</TableCell>
                <TableCell align="right">{this.props.executionPackages}€</TableCell>
            </TableRow>)
        }

        rows.push(<TableRow key={3} selected={true}>
            <TableCell component="th" scope="row" variant='head'>
                Total
            </TableCell>
            <TableCell align="right"/>
            <TableCell variant='head' align="right" colSpan={2}>{plan.price + this.props.executionPackages}€ per month</TableCell>
        </TableRow>);

        return <div className={classes.root}>
            <Grid container spacing={5} justify="center" alignItems='flex-start'>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h4" color="textPrimary" className={classes.title}>
                        {this.props.t('billing.plan')}
                    </Typography>
                    <Card className={classes.card}>
                        <CardHeader
                            title={plan.label}
                            titleTypographyProps={{align: 'center'}}
                            subheaderTypographyProps={{align: 'center'}}
                        />
                        <CardContent>
                            <div className={classes.cardPricing}>
                                <Typography component="h2" variant="h3" color="textPrimary">
                                    {plan.price}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                    €/mo
                                </Typography>
                            </div>
                            <ul>
                                <Typography component="li" variant="subtitle1" align="center" key={1}>
                                    100 Watches
                                </Typography>
                                <Typography component="li" variant="subtitle1" align="center" key={2}>
                                    100 Notifications
                                </Typography>
                                <Typography component="li" variant="subtitle1" align="center" key={3}>
                                    {plan.executions} Executions inclusive
                                </Typography>
                                <Typography component="li" variant="subtitle1" align="center" key={4}>
                                    Minimum interval {plan.minimumInterval} minutes
                                </Typography>
                                {plan.hasMetrics && <Typography component="li" variant="subtitle1" align="center" key={5}>
                                    Monitoring Metrics
                                </Typography>}
                                {!this.props.user.userObject.hadTrial &&
                                <Typography component="li" variant="subtitle1" align="center" key={6}>
                                    <b>14 Days trial period</b>
                                </Typography>}
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h4" color="textPrimary" className={classes.title}>
                        {this.props.t('address.label')}
                    </Typography>
                    <Typography variant="subtitle1">
                        {address.company ? <Fragment>{address.company} <br/></Fragment> : ''}
                        {address.firstName} {address.lastName} <br/>
                        {address.streetAddress} <br/>
                        {address.extendedAddress ? <Fragment>{address.extendedAddress} <br/></Fragment> : ''}
                        {address.region ? <Fragment>{address.region} <br/></Fragment> : ''}
                        {address.postalCode} {address.city} <br/>
                        {country ? <Fragment>{country.name} <br/></Fragment> : ''}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="h4" color="textPrimary" className={classes.title}>
                        {this.props.t('billing.payment')}
                    </Typography>
                    <Paper className={classes.summaryPaper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Price per month</TableCell>
                                    <TableCell align="right">Total Price per month</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows}
                            </TableBody>
                        </Table>
                    </Paper>
                    {!this.props.user.userObject.hadTrial ? <Paper className={classes.trialInformation}>
                        <Typography variant='h5'>{this.props.t('billing.freeTrial')}</Typography>
                        {this.props.t('billing.freeTrialText')}
                    </Paper> : ''}
                    <FormControlLabel
                        control={
                            <Checkbox/>
                        }
                        label={<span
                            dangerouslySetInnerHTML={{__html: this.props.t('billing.termsAndConditionsText', {link: 'https://metric.observer/terms'})}}/>}
                    />
                          <FormControlLabel
                              control={
                                  <Checkbox/>
                              }
                              label={this.props.t('billing.revocationPolicyText')}
                          />
                          <Divider className={classes.divider} />
                    <span>
                        <Typography variant='subtitle1'>Powered by</Typography>
                        <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png" alt="PayPal Logo" />
                    </span>
                </Grid>
            </Grid>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    const {user} = state;
    return {user}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Order);