import React from 'react';
import {FormControl, InputLabel, Select} from '@material-ui/core'

const SimpleSelect = (props) => {

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return <FormControl variant="outlined" {...props} >
        <InputLabel ref={inputLabel}>
            {props.label}
        </InputLabel>
        <Select labelWidth={labelWidth}
                name={props.name}
                value={props.value}
                onChange={props.onChange}>
            {props.children}
        </Select>
    </FormControl>
};
export default SimpleSelect