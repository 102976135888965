import React, {Component} from 'react';
import {bindActionCreators, compose} from "redux";
import {Box, CardContent, Grid, Toolbar, Typography, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ButtonLink} from "../../Utilities/ButtonLink";
import CloseIcon from "@material-ui/icons/Close";
import Rest from "../../../core/Rest";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {AreaSeries, Hint, HorizontalGridLines, LineSeries, VerticalBarSeries, VerticalGridLines, XAxis, XYPlot, YAxis} from "react-vis";
import ReactResizeDetector from 'react-resize-detector';
import DiscreteColorLegend from "react-vis/es/legends/discrete-color-legend";
import moment from "moment";
import Paper from "@material-ui/core/Paper";

const useStyles = theme => ({
    root: {},
    grow: {
        flexGrow: 1,
    },
    hint: {
        padding: theme.spacing(1),
        margin: theme.spacing(0.5),
    },
    checkDurationCard: {
        marginTop: theme.spacing(2)
    }
});

class Metrics extends Component {

    averageChartRef = React.createRef();
    alertingChartRef = React.createRef();
    checkDurationChartRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            isMetricsFetching: false,
            watch: {},
            metrics: {},
            averageChart: {
                width: 0,
            },
            alertingChart: {
                width: 0,
            },
            checkDurationChart: {
                width: 0,
            }
        };
    }

    componentDidMount() {
        this.fetchWatch();
        this.fetchWatchMetrics()
    }


    fetchWatchMetrics = () => {

        this.setState({isMetricsFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'metrics/' + this.props.match.params.id + '/data',
        }).then(
            response => {
                this.setState({
                    metrics: response.response,
                })
            }
        ).then(() => {
            this.setState({isMetricsFetching: false});
        })
    };

    fetchWatch = () => {

        this.setState({isFetching: true});

        Rest.fetch({
            method: 'GET',
            endpoint: 'watches/' + this.props.match.params.id,
        }).then(
            response => {
                this.setState({
                    watch: response.response,
                })
            }
        ).then(() => {
            this.setState({isFetching: false});
        })
    };

    handleResize = () => {
        const specs = this.averageChartRef.current.getBoundingClientRect();
        const alertingSpecs = this.alertingChartRef.current.getBoundingClientRect();
        const checkDurationSpecs = this.checkDurationChartRef.current.getBoundingClientRect();
        this.setState({averageChart: specs, alertingChart: alertingSpecs, checkDurationChart: checkDurationSpecs})
    };

    getAlertingValues = (field) => {
        if (!this.state.metrics.valueEntries) {
            return [];
        }

        return this.state.metrics.alertingEntries.map((entry) => {
            return {
                x: moment(entry.timestamp).format("YYYY/MM/DD - H:mm"),
                y: entry[field]
            }
        });
    };

    getAverageCheckDuration = () => {
        if (!this.state.metrics.checkDurationEntries) {
            return [];
        }

        return this.state.metrics.checkDurationEntries.map((entry) => {
            return {
                x: moment(entry.timestamp).format("YYYY/MM/DD - H:mm"),
                y: entry.stats.avg || 0
            }
        });
    };

    getAverageValues = () => {
        if (!this.state.metrics.valueEntries) {
            return [];
        }

        return this.state.metrics.valueEntries.map((entry) => {
            return {
                x: moment(entry.timestamp).format("YYYY/MM/DD - H:mm"),
                y: entry.stats.avg || 0
            }
        });
    };

    getMaxValues = () => {
        if (!this.state.metrics.valueEntries) {
            return [];
        }

        return this.state.metrics.valueEntries.map((entry) => {
            return {
                x: moment(entry.timestamp).format("YYYY/MM/DD - H:mm"),
                y: entry.stats.max || 0
            }
        });
    };

    getFullAverageValue = () => {
        if (!this.state.metrics.fullStats) {
            return 0;
        }

        return this.state.metrics.fullStats.value.avg;
    };

    getThresholdValue = () => {
        if (!this.state.watch.data) {
            return 0;
        }

        const input = this.props.inputs.find((input) => {
            return input.identifier === this.state.watch.input
        });

        const fieldName = input.thresholdField;

        const value = this.state.watch.data.find((field) => {
            return field.identifier === fieldName
        });

        if(!value || !value.value) {
            return 0
        }

        return value.value;
    };

    render() {
        const classes = this.props.classes;

        return <div className={classes.root}>
            <Toolbar disableGutters={true}>
                <Box>
                    <Typography variant='h4'>{this.state.watch.name}</Typography>
                </Box>
                <div className={classes.grow}/>
                <Box justifyContent="flex-end">
                    <ButtonLink variant="contained" color="primary" to={'/'}>
                        <CloseIcon/> {this.props.t('close')}
                    </ButtonLink>
                </Box>
            </Toolbar>
            <Grid container spacing={2}>
                <Grid item sm={12} md={8}>
                    <Card >
                        <CardHeader
                            title='Average Value'
                        />
                        <CardContent>
                            <div ref={this.averageChartRef}>
                                <ReactResizeDetector handleWidth onResize={this.handleResize}/>
                                <XYPlot
                                    onMouseLeave={() => this.setState({hoverValue: null})}
                                    xType="ordinal"
                                    width={this.state.averageChart.width}
                                    height={600}
                                    margin={{left: 80, right: 10, top: 10, bottom: 80}}>
                                    <VerticalGridLines/>
                                    <HorizontalGridLines/>
                                    <XAxis tickLabelAngle={-45}/>
                                    <YAxis/>
                                    <AreaSeries
                                        onNearestX={(value) => this.setState({hoverValue: value})}
                                        onSeriesMouseOut={() => this.setState({hoverValue: null})}
                                        color={this.props.theme.palette.primary.light}
                                        data={this.getAverageValues()}
                                    />
                                    <LineSeries
                                        onNearestX={(value) => this.setState({hoverValue: value})}
                                        onSeriesMouseOut={() => this.setState({hoverValue: null})}
                                        color={this.props.theme.palette.secondary.light}
                                        data={this.getMaxValues()}
                                    />
                                    <LineSeries
                                        color={this.props.theme.palette.error.main}
                                        data={this.getAverageValues().map((entry) => {
                                            entry.y = this.getThresholdValue();
                                            return entry;
                                        })}
                                    />
                                    {this.state.hoverValue && (
                                        <Hint value={this.state.hoverValue}>
                                            <Paper className={classes.hint} elevation={8}>
                                                <Typography variant={'subtitle2'}>{this.state.hoverValue.x}:</Typography>
                                                <Typography variant={'subtitle1'}>{this.state.hoverValue.y}</Typography>
                                            </Paper>
                                        </Hint>
                                    )}
                                </XYPlot>
                                <DiscreteColorLegend
                                    orientation="horizontal"
                                    items={[
                                        {title: 'Maximum value per timestamp', color: this.props.theme.palette.secondary.light},
                                        {title: 'Average value per timestamp', color: this.props.theme.palette.primary.light},
                                        {title: 'Threshold', color: this.props.theme.palette.error.main},
                                    ]}/>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Card>
                        <CardHeader
                            title='Alerting'
                        />
                        <CardContent>
                            <div ref={this.alertingChartRef}>
                                <ReactResizeDetector handleWidth onResize={this.handleResize}/>
                                <XYPlot
                                    stackBy='y'
                                    xType="ordinal"
                                    width={this.state.alertingChart.width}
                                    height={200}
                                    margin={{left: 80, right: 10, top: 10, bottom: 80}}>
                                    <VerticalGridLines/>
                                    <HorizontalGridLines/>
                                    <XAxis tickLabelAngle={-45}/>
                                    <YAxis/>
                                    <VerticalBarSeries
                                        color={this.props.theme.palette.primary.light}
                                        data={this.getAlertingValues(false)}
                                    />
                                    <VerticalBarSeries
                                        color={this.props.theme.palette.secondary.light}
                                        data={this.getAlertingValues(true)}
                                    />
                                </XYPlot>
                                <DiscreteColorLegend
                                    orientation="horizontal"
                                    items={[
                                        {title: 'Yes', color: this.props.theme.palette.secondary.light},
                                        {title: 'No', color: this.props.theme.palette.primary.light},
                                    ]}/>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={classes.checkDurationCard}>
                        <CardHeader
                            title='Check duration'
                        />
                        <CardContent>
                            <div ref={this.checkDurationChartRef}>
                                <ReactResizeDetector handleWidth onResize={this.handleResize}/>
                                <XYPlot
                                    xType="ordinal"
                                    width={this.state.checkDurationChart.width}
                                    height={200}
                                    margin={{left: 80, right: 10, top: 10, bottom: 80}}>
                                    <VerticalGridLines/>
                                    <HorizontalGridLines/>
                                    <XAxis tickLabelAngle={-45}/>
                                    <YAxis/>
                                    <AreaSeries
                                        color={this.props.theme.palette.primary.light}
                                        data={this.getAverageCheckDuration()}
                                    />
                                </XYPlot>
                                <DiscreteColorLegend
                                    orientation="horizontal"
                                    items={[
                                        {title: 'Average check duration', color: this.props.theme.palette.primary.light}
                                    ]}/>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch),
    }
};

function mapStateToProps(state) {
    const inputs = state.inputs.list || [];
    return {inputs}
}


export default compose(
    withStyles(useStyles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(Metrics);