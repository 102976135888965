import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import Navigation from "../Navigations/Navigation";
import {withTranslation} from 'react-i18next';
import {Toolbar} from "@material-ui/core";
import {Redirect, Route, Switch} from "react-router"
import Notifications from "../Notifications/Notifications";
import {fetchInputList} from "../../actions/inputActions";
import {fetchOutputList} from "../../actions/outputActions";
import {connect} from "react-redux";
import Profile from "../Profile/Profile";
import {fetchUser} from "../../actions/userActions";
import LoadingScreen from "../Utilities/LoadingScreen";
import {push} from "connected-react-router";
import PaymentRouter from "../Payment/Router";
import WatchRouter from "../Watches/Router";

const useStyles = theme => ({
    main: {
        flexGrow: 1,
        padding: theme.spacing(4),
        marginLeft: '56px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    }
});

class LoggedIn extends Component {

    componentDidMount() {
        this.props.actions.fetchInputList();
        this.props.actions.fetchUser();
        this.props.actions.fetchOutputList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (
            this.props.user.userObject &&
            this.props.user.userObject.roles.indexOf('SUBSCRIPTION-ACTIVE') === -1 &&
            this.props.location.pathname.indexOf('/payment') !== 0) {
            this.props.redirectToPayment();
        }
    }

    render() {

        const classes = this.props.classes;

        const match = this.props.match;

        if (!this.props.user.userObject) {
            return <LoadingScreen isFetching={this.props.user.isFetching}/>
        }

        const routes = [
            <Route key={1} exact path={match.url} render={() => <Redirect to={'/watches'}/>}/>,
            <Route key={2} path={match.url + 'watches'} component={WatchRouter}/>,
            <Route key={3} path={match.url + 'notifications'} component={Notifications}/>,
            <Route key={4} path={match.url + 'profile'} component={Profile}/>,
            <Route key={5} path={match.url + 'payment'} component={PaymentRouter}/>
        ];

        return <div>
            <Navigation match={match}/>
            <Toolbar variant='dense'/>
            <main className={classes.main}>
                <Switch>
                    {routes}
                </Switch>
            </main>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchInputList, fetchOutputList, fetchUser}, dispatch),
        redirectToPayment: () => {
            dispatch(push('/payment'))
        }
    }
};

function mapStateToProps(state) {
    const {user} = state;
    return {user}
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation())(LoggedIn);